import React, { useState } from 'react';
import { ArrowLeft } from 'lucide-react';

const Button = ({ onClick, children, className = '' }) => (
  <button
    onClick={onClick}
    className={`bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105 ${className}`}
  >
    {children}
  </button>
);

const Select = ({ onValueChange, children, placeholder }) => (
  <div className="relative">
    <select
      onChange={(e) => onValueChange(e.target.value)}
      className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
    >
      <option value="">{placeholder}</option>
      {children}
    </select>
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </div>
  </div>
);

const Input = ({ type, placeholder, value, onChange }) => (
  <input
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
  />
);

const Page = ({ title, children, onBack }) => (
  <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
    <div className="bg-white p-8 rounded-2xl shadow-xl max-w-md w-full">
      <div className="flex items-center mb-6">
        {onBack && (
          <button onClick={onBack} className="mr-4">
            <ArrowLeft size={24} />
          </button>
        )}
        <h1 className="text-2xl font-bold text-center flex-grow">{title}</h1>
      </div>
      {children}
    </div>
  </div>
);

const MainPage = ({ onNavigate }) => (
  <Page title="Urology Calculator">
    <div className="grid grid-cols-2 gap-4">
      <Button onClick={() => onNavigate('prostateCancer')}>Prostate Cancer</Button>
      <Button onClick={() => onNavigate('kidneyCancer')}>Kidney Cancer</Button>
      <Button onClick={() => onNavigate('luts')}>LUTS</Button>
      <Button onClick={() => onNavigate('misc')}>Misc</Button>
    </div>
  </Page>
);

const ProstateCancerPage = ({ onNavigate, onBack }) => (
  <Page title="Prostate Cancer" onBack={onBack}>
    <div className="grid grid-cols-1 gap-4">
      <Button onClick={() => onNavigate('cpg')}>Cambridge Prognostic Group (CPG)</Button>
      <Button onClick={() => onNavigate('briganti')}>Briganti Nomogram</Button>
      <Button onClick={() => onNavigate('partin')}>Partin Tables</Button>
      <Button onClick={() => onNavigate('psaVelocity')}>PSA Velocity</Button>
      <Button onClick={() => onNavigate('psaDoublingTime')}>PSA Doubling Time</Button>
    </div>
  </Page>
);

const KidneyCancerPage = ({ onNavigate, onBack }) => (
  <Page title="Kidney Cancer" onBack={onBack}>
    <div className="grid grid-cols-1 gap-4">
      <Button onClick={() => onNavigate('leibovich')}>Leibovich Score</Button>
      <Button onClick={() => onNavigate('renal')}>RENAL Score</Button>
    </div>
  </Page>
);

const LUTSPage = ({ onNavigate, onBack }) => (
  <Page title="LUTS" onBack={onBack}>
    <div className="grid grid-cols-1 gap-4">
      <Button onClick={() => onNavigate('ipss')}>IPSS</Button>
      <Button onClick={() => onNavigate('iief5')}>IIEF-5</Button>
      <Button onClick={() => onNavigate('roks')}>ROKS</Button>
    </div>
  </Page>
);

const MiscPage = ({ onBack }) => (
  <Page title="Misc" onBack={onBack}>
    <p className="text-center">Misc content goes here</p>
  </Page>
);

const CPGCalculator = ({ onBack }) => {
  const [gleasonScore, setGleasonScore] = useState('');
  const [psa, setPsa] = useState('');
  const [tumorStage, setTumorStage] = useState('');
  const [cpgScore, setCpgScore] = useState(null);

  const calculateCPG = () => {
    const psaValue = parseFloat(psa);
    if (gleasonScore === '6' && psaValue < 10 && ['T1', 'T2'].includes(tumorStage)) {
      setCpgScore(1);
    } else if (
      (gleasonScore === '3+4' && psaValue < 10 && ['T1', 'T2'].includes(tumorStage)) ||
      (psaValue >= 10 && psaValue <= 20 && ['T1', 'T2'].includes(tumorStage))
    ) {
      setCpgScore(2);
    } else if (
      (gleasonScore === '3+4' && psaValue >= 10 && psaValue <= 20 && ['T1', 'T2'].includes(tumorStage)) ||
      (gleasonScore === '4+3' && ['T1', 'T2'].includes(tumorStage))
    ) {
      setCpgScore(3);
    } else if (gleasonScore === '8' || psaValue > 20 || tumorStage === 'T3') {
      setCpgScore(4);
    } else if (gleasonScore === '9-10' || tumorStage === 'T4') {
      setCpgScore(5);
    } else {
      setCpgScore(null);
    }
  };

  return (
    <Page title="CPG Calculator" onBack={onBack}>
      <div className="space-y-6">
        <Select onValueChange={setGleasonScore} placeholder="Gleason Score">
          <option value="6">6 (Grade Group 1)</option>
          <option value="3+4">3+4=7 (Grade Group 2)</option>
          <option value="4+3">4+3=7 (Grade Group 3)</option>
          <option value="8">8 (Grade Group 4)</option>
          <option value="9-10">9-10 (Grade Group 5)</option>
        </Select>
        <Input
          type="number"
          placeholder="PSA (ng/ml)"
          value={psa}
          onChange={(e) => setPsa(e.target.value)}
        />
        <Select onValueChange={setTumorStage} placeholder="Tumor Stage">
          <option value="T1">T1</option>
          <option value="T2">T2</option>
          <option value="T3">T3</option>
          <option value="T4">T4</option>
        </Select>
        <Button onClick={calculateCPG} className="w-full">
          Calculate CPG
        </Button>
      </div>
      {cpgScore !== null && (
        <div className="mt-6 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4" role="alert">
          <p className="font-bold">CPG Score Result</p>
          <p>The calculated CPG score is: <strong>{cpgScore}</strong></p>
        </div>
      )}
    </Page>
  );
};

const BrigantiCalculator = ({ onBack }) => {
  const [age, setAge] = useState('');
  const [psa, setPsa] = useState('');
  const [clinicalStage, setClinicalStage] = useState('');
  const [gleasonPrimary, setGleasonPrimary] = useState('');
  const [gleasonSecondary, setGleasonSecondary] = useState('');
  const [positiveCores, setPositiveCores] = useState('');
  const [lniRisk, setLniRisk] = useState(null);

  const assignPointsAge = (age) => {
    // Placeholder logic - adjust according to actual Briganti nomogram
    return age < 50 ? 0 : age < 60 ? 1 : age < 70 ? 2 : 3;
  };

  const assignPointsPsa = (psa) => {
    // Placeholder logic - adjust according to actual Briganti nomogram
    return psa < 4 ? 0 : psa < 10 ? 1 : psa < 20 ? 2 : 3;
  };

  const assignPointsClinicalStage = (stage) => {
    // Placeholder logic - adjust according to actual Briganti nomogram
    return stage === 'T1c' ? 0 : stage === 'T2a' ? 1 : stage === 'T2b' ? 2 : 3;
  };

  const assignPointsGleason = (primary, secondary) => {
    // Placeholder logic - adjust according to actual Briganti nomogram
    const total = parseInt(primary) + parseInt(secondary);
    return total <= 6 ? 0 : total === 7 ? 2 : 4;
  };

  const assignPointsPositiveCores = (percentage) => {
    // Placeholder logic - adjust according to actual Briganti nomogram
    return percentage < 20 ? 0 : percentage < 40 ? 1 : percentage < 60 ? 2 : 3;
  };

  const calculateTotalScore = (age, psa, stage, gleasonPrimary, gleasonSecondary, positiveCores) => {
    return (
      assignPointsAge(age) +
      assignPointsPsa(psa) +
      assignPointsClinicalStage(stage) +
      assignPointsGleason(gleasonPrimary, gleasonSecondary) +
      assignPointsPositiveCores(positiveCores)
    );
  };

  const calculateLniRisk = (totalScore) => {
    // Placeholder logic - adjust according to actual Briganti nomogram
    return totalScore / 16 * 100; // Simplified calculation for demonstration
  };

  const calculateBrigantiScore = () => {
    const totalScore = calculateTotalScore(
      parseInt(age),
      parseFloat(psa),
      clinicalStage,
      gleasonPrimary,
      gleasonSecondary,
      parseFloat(positiveCores)
    );
    const risk = calculateLniRisk(totalScore);
    setLniRisk(risk.toFixed(2));
  };

  return (
    <Page title="Briganti Nomogram Calculator" onBack={onBack}>
      <div className="space-y-4">
        <Input
          type="number"
          placeholder="Age"
          value={age}
          onChange={(e) => setAge(e.target.value)}
        />
        <Input
          type="number"
          placeholder="PSA Level"
          value={psa}
          onChange={(e) => setPsa(e.target.value)}
        />
        <Select onValueChange={setClinicalStage} placeholder="Clinical T Stage">
          <option value="T1c">T1c</option>
          <option value="T2a">T2a</option>
          <option value="T2b">T2b</option>
          <option value="T2c">T2c</option>
        </Select>
        <Select onValueChange={setGleasonPrimary} placeholder="Gleason Primary">
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </Select>
        <Select onValueChange={setGleasonSecondary} placeholder="Gleason Secondary">
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </Select>
        <Input
          type="number"
          placeholder="Percentage of Positive Cores"
          value={positiveCores}
          onChange={(e) => setPositiveCores(e.target.value)}
        />
        <Button onClick={calculateBrigantiScore} className="w-full">
          Calculate Briganti Score
        </Button>
      </div>
      {lniRisk !== null && (
        <div className="mt-6 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4" role="alert">
          <p className="font-bold">Briganti Score Result</p>
          <p>The risk of lymph node invasion is: <strong>{lniRisk}%</strong></p>
        </div>
      )}
    </Page>
  );
};

const App = () => {
  const [currentPage, setCurrentPage] = useState('main');
  const [pageStack, setPageStack] = useState([]);

  const navigateTo = (page) => {
    setPageStack([...pageStack, currentPage]);
    setCurrentPage(page);
  };

  const goBack = () => {
    const newStack = [...pageStack];
    const previousPage = newStack.pop();
    setPageStack(newStack);
    setCurrentPage(previousPage);
  };

  const renderPage = () => {
    switch (currentPage) {
      case 'main':
        return <MainPage onNavigate={navigateTo} />;
      case 'prostateCancer':
        return <ProstateCancerPage onNavigate={navigateTo} onBack={goBack} />;
      case 'kidneyCancer':
        return <KidneyCancerPage onNavigate={navigateTo} onBack={goBack} />;
      case 'luts':
        return <LUTSPage onNavigate={navigateTo} onBack={goBack} />;
      case 'misc':
        return <MiscPage onBack={goBack} />;
      case 'cpg':
        return <CPGCalculator onBack={goBack} />;
      case 'briganti':
        return <BrigantiCalculator onBack={goBack} />;
      default:
        return <Page title={currentPage} onBack={goBack}>
          <p className="text-center">{currentPage} calculator goes here</p>
        </Page>;
    }
  };

  return renderPage();
};

export default App;
